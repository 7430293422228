<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <el-backtop target=".el-main"></el-backtop>

    <div class="search">
      <el-input placeholder="请输入手机号" v-model="phone"></el-input>
      <el-input
        placeholder="请输入要查询的钱包地址"
        v-model="walletUrl"
      ></el-input>
      <div>
        <el-button type="primary" @click="searchList">搜索</el-button>
        <el-button type="info" @click="reset">重置</el-button>
      </div>
    </div>

    <div class="table_con">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="id" label="ID" width="100"> </el-table-column>
        <el-table-column prop="phone" label="账号" width="140">
        </el-table-column>
        <el-table-column prop="wallet" label="钱包地址" width="230">
        </el-table-column>
        <el-table-column
          prop="tokenid"
          label="tokenId"
          width="190"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="contractId" label="作品集合约" width="230">
        </el-table-column>
        <el-table-column
          prop="nfturl"
          label="用户头像"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.nfturl"
              :preview-src-list="[scope.row.nfturl]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
          prop="submittime"
          label="用户提交时间"
          width="180"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="status" label="状态" width="80" align="center">
        </el-table-column>
        <el-table-column fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.status == '正常'" class="operation">
              <div>已通过</div>
              <el-button type="text" size="small" @click="editPhoto(scope.row)"
                >编辑照片</el-button
              >
            </div>

            <div class="operation" v-else-if="scope.row.status == '待审批'">
              <el-button
                @click="handleClick(scope.row, 1)"
                type="text"
                size="small"
                >审核通过</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleClick(scope.row, 2)"
                >拒绝通过</el-button
              >
            </div>
            <div class="operation" v-else-if="scope.row.status == '审核拒绝'">
              <div>已拒绝</div>
            </div>
          </template>
        </el-table-column>
        <el-dialog
          title="图片剪裁"
          :visible.sync="dialogVisible"
          append-to-body
        >
          <div class="cropper-content">
            <div class="cropper" style="text-align: center">
              <vueCropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.outputSize"
                :outputType="option.outputType"
                :info="option.info"
                :canScale="option.canScale"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :fixed="option.fixed"
                :fixedBox="option.fixedBox"
                :fixedNumber="option.fixedNumber"
              ></vueCropper>
            </div>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleCropping()">确认</el-button>
          </div>
        </el-dialog>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { VueCropper } from "vue-cropper";

import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
export default {
  components: { VueCropper, Breadcrumb, Pagination },
  inject: ["reload"],
  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "NFT审核",
        },
        {
          name: "NFT关联审核",
        },
      ], // 面包屑数据

      phone: "",
      walletUrl: "",

      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100, 200],

      dialogVisible: false,

      // 裁剪组件的基础配置option

      option: {
        img: "", // 裁剪图片的地址

        info: true, // 裁剪框的大小信息

        outputSize: 0.8, // 裁剪生成图片的质量

        outputType: "png", // 裁剪生成图片的格式

        canScale: false, // 图片是否允许滚轮缩放

        autoCrop: true, // 是否默认生成截图框

        autoCropWidth: 300, // 默认生成截图框宽度

        autoCropHeight: 300, // 默认生成截图框高度

        fixedBox: false, // 固定截图框大小 不允许改变

        fixed: true, // 是否开启截图框宽高固定比例

        fixedNumber: [1, 1], // 截图框的宽高比例

        full: true, // 是否输出原图比例的截图

        canMoveBox: false, // 截图框能否拖动

        original: false, // 上传图片按照原始比例渲染

        centerBox: true, // 截图框是否被限制在图片里面

        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高

        canMove: true,
      },
    };
  },

  mounted() {
    showLoading();
    const opt = {
      url: reqUrl.getNftRelationExamineList,
      method: "POST",
      params: JSON.stringify({
        field1: "",
        field2: "",
        nonce: "",
        order1: "",
        order2: "",
        pageNum: 0,
        pageSize: 0,
        sign: "",
        token: "",
      }),
      resFunc: (res) => {
        console.log(res.data);
        this.tableData = res.data.data.list;
        this.pageLength = res.data.data.total;
        this.pageSize = res.data.data.pageSize;
        hideLoading();

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }

        res.data.data.list.map((item) => {
          this.changeItem(item);
        });
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      if (this.phone || this.walletUrl) {
        showLoading();
        const opt = {
          url: reqUrl.getNftRelationExamineList,
          method: "POST",
          params: JSON.stringify({
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            phone: this.phone,
            sign: "",
            token: "",
            wallet: this.walletUrl,
          }),
          resFunc: (res) => {
            console.log(res.data);
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            hideLoading();

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }

            res.data.data.list.map((item) => {
              this.changeItem(item);
            });
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    changeItem(item) {
      if (item.status == 3) {
        item.status = "审核拒绝";
      } else if (item.status == 2) {
        item.status = "正常";
      } else if (item.status == 1) {
        item.status = "待审批";
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getNftRelationExamineList,
        method: "POST",
        params: JSON.stringify({
          field1: "",
          field2: "",
          nonce: "",
          order1: "",
          order2: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          sign: "",
          token: "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          this.currentPage = data.pageIndex;
          hideLoading();

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }

          localStorage.setItem("requestURLdataIndex", this.currentPage);
          res.data.data.list.map((item) => {
            this.changeItem(item);
          });
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row, index) {
      if (index == 1) {
        this.$confirm("您确定要执行该操作吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            showLoading();
            const opt = {
              url: reqUrl.examineNftRelation,
              method: "POST",
              params: JSON.stringify({
                auditor: localStorage.getItem("userName"),
                id: row.id,
                reason: "",
                result: "1",
                requestid: row.requestid,
                url: row.nfturl,
                tokenid: row.tokenid,
                contractId: row.contractId,
              }),
              resFunc: (res) => {
                console.log(res.data);

                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  row.status = "正常";
                  hideLoading();
                }
                if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }

                hideLoading();
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
                hideLoading();
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      } else if (index == 2) {
        this.$confirm("您确定要执行该操作吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            showLoading();
            const opt = {
              url: reqUrl.examineNftRelation,
              method: "POST",
              params: JSON.stringify({
                auditor: localStorage.getItem("userName"),
                id: row.id,
                reason: "",
                result: "0",
                requestid: row.requestid,
                url: row.nfturl,
                tokenid: row.tokenid,
                contractId: row.contractId,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  row.status = "审核拒绝";
                  hideLoading();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                hideLoading();
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });
          });
      }
    },

    editPhoto(row) {
      // this.$nextTick(() => {
      this.option.img = row.nfturl;
      this.dialogVisible = true;
      localStorage.setItem("croppingImgId", row.id);
      localStorage.setItem("croppingImgNFTUrl", row.nfturl);
      localStorage.setItem("croppingImgUserId", row.userid);
      // });
    },

    handleCropping() {
      this.$refs.cropper.getCropBlob((data) => {
        const formData = new FormData();
        formData.append("file", data);
        formData.append("userId", localStorage.getItem("croppingImgUserId"));
        formData.append("scene", 2);

        axios.defaults.headers.post["Authorization"] = localStorage.getItem(
          "resHeadersAuthorization"
        );
        axios({
          method: "POST",
          url: "https://examine.quchuxing.com/examine/imageUpload",
          header: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
          .then((res) => {
            if (res.data.code == 0) {
              const opt = {
                url: reqUrl.editNftImageUrl,
                method: "POST",
                params: {
                  id: localStorage.getItem("croppingImgId"),
                  type: "2",
                  url: res.data.data.url,
                  userId: localStorage.getItem("croppingImgUserId"),
                },
                resFunc: (res) => {
                  console.log(res.data);
                  if (res.data.code == 0) {
                    this.dialogVisible = false;

                    const opt = {
                      url: reqUrl.getNftRelationExamineList,
                      method: "POST",
                      params: JSON.stringify({
                        field1: "",
                        field2: "",
                        nonce: "",
                        order1: "",
                        order2: "",
                        pageNum: localStorage.getItem("requestURLdataIndex"),
                        pageSize: 0,
                        sign: "",
                        token: "",
                      }),
                      resFunc: (res) => {
                        console.log(res.data);
                        this.tableData = res.data.data.list;
                        this.pageLength = res.data.data.total;
                        this.pageSize = res.data.data.pageSize;

                        if (res.data.code != 0) {
                          this.$notify.error({
                            title: "错误",
                            message: res.data.message,
                          });
                        }

                        res.data.data.list.map((item) => {
                          this.changeItem(item);
                        });
                      },
                      errFunc: (err) => {
                        this.$notify.error({
                          title: "错误",
                          message: err,
                        });
                        console.log(err, "err1");
                      },
                    };
                    request(opt);
                  } else if (res.data.code != 0) {
                    this.$notify.error({
                      title: "错误",
                      message: res.data.message,
                    });
                  }
                },
                errFunc: (err) => {
                  this.$notify.error({
                    title: "错误",
                    message: err,
                  });
                  console.log(err, "err1");
                },
              };
              request(opt);
            } else if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            }
          })
          .catch((e) => {
            this.$notify.error({
              title: "错误",
              message: e,
            });
          });
      });
    },

    reset() {
      if (this.phone || this.walletUrl) {
        this.phone = "";
        this.walletUrl = "";
        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;

  .el-input {
    width: 250px;
    margin-right: 10px;
  }
}
.table_con {
  margin-top: 10px;
}

.operation {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #b4b4b4;
  font-weight: bold;

  .el-button + .el-button {
    margin-left: 0;
    margin-top: 2px;
  }

  .el-button {
    border: 1px #ccc solid;
    color: #666;
    background: #d9d9d9;
    padding: 10px;
  }
}
.cropper-content {
  width: 500px;
  height: 500px;
  background: pink;
  margin: 0 auto;
}
.cropper {
  width: 500px;
  height: 500px;
  background: yellow;
}
</style>
